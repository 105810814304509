import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";

export default function Terms(props) {

    //On component mount
    useEffect(() => {
        var hash = window.location.hash;

        if (hash!=="") {
            hash = hash.slice(1); //Remove first character
            var element = document.getElementById(hash);

            if (element!==null) {
                var position = element.getBoundingClientRect().top - 90; //Substract 90ps from topbar offset

                window.scrollTo({top:position});
            }
        }
    }, []);

    return (
        <div className="terms">
            <Layout>
                <div className='terms-block'>
                    {config.terms.termsText.map((term, key1) => {
                        return(
                            <div  key={key1} className='block' id={term.id ? term.id:""}>
                                <h1 className="heading" dangerouslySetInnerHTML={{__html:term.heading}}></h1>
                                <div className='paragraphs'>
                                    {term.body.map((paragraph, key2) => {
                                        return(
                                            <p key={key2} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Layout>
        </div>
    )
}
