import React, {useEffect, useState} from 'react';
import './styles/general.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Pages
import Home from "./pages/home/main";
import Services from "./pages/services/main";
import Leadership from "./pages/leadership/main";
import Coach1 from "./pages/coach1/main";
import Coach2 from "./pages/coach2/main";
import Coach3 from "./pages/coach3/main";
import Coach4 from "./pages/coach4/main";
import Contact from "./pages/contact/main";
import Terms from "./pages/terms/main";
import NotFound from "./pages/not-found/main";


export default function Web() {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route index element={<Home />} />
                <Route path="services" element={<Services />}/>
                <Route path="leadership" element={<Leadership />}/>
                <Route path="coach1" element={<Coach1 />}/>
                <Route path="coach2" element={<Coach2 />}/>
                <Route path="coach3" element={<Coach3 />}/>
                <Route path="coach4" element={<Coach4 />}/>
                <Route path="contact" element={<Contact />} />
                <Route path="terms" element={<Terms />}/>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
