import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import jsonp from 'jsonp';

export default function Footer(props) {
    const [currYear, setCurrYear] = useState(new Date().getFullYear());
    const [email, setEmail] = useState("");
    const [subscribed, setSubscribed] = useState(false);



    return (
        <footer className="footer">
            <div className="inner">
                <div className='columns'>
                    <div className="col col1">
                        <h5 className="col-heading">{config.menuTitle}</h5>
                        <nav className="col-menu">
                            {config.menuItems.map((item, key) => {
                                return(
                                    <React.Fragment key={key}>
                                        {item.hasOwnProperty("url") ?
                                        <a className="item" key={key} href={item.url}>
                                            <span className="name">{item.name}</span>
                                        </a>
                                        :
                                        <a className="item" key={key} onClick={onPopOpen}>
                                            <span className="name">{item.name}</span>
                                        </a>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </nav>
                    </div>

                    <div className="col col2">
                        <h5 className="col-heading">{config.contact}</h5>
                        <div className="col-address">
                            {config.address.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </div>

                    <div className="col col3">
                        <h5 className="col-heading">{config.newsletterTitle}</h5>
                        <p className='col-newsletter'>{config.newsletterText}</p>
                        <a className='subscribe-btn' dangerouslySetInnerHTML={{__html:config.subscribeBtn}} onClick={onSubscribe}></a>

                    </div>
                </div>

                <div className='privacy-links'>
                    <a className="terms-link" href={config.terms.url}>{config.terms.termsLabel}</a>
                    <a className="privacy-link" href={config.privacy.url}>{config.privacy.privacyLabel}</a>
                </div>

                <div className="icons">
                    <a className="icon icon1" href={config.icons[0].url} target="_blank" alt="Click here to view LinkedIn page"></a>
                    {/* <a className="icon icon2" href={config.icons[1].url} target="_blank"></a> */}
                </div>

                <div>
                    <p className="copyright">{"© "+currYear+" ezeni"}</p>
                </div>
            </div>
        </footer>
    )

    function onSubscribe() {

        props.onSubscribe();

    }

    function onPopOpen() {
        ScrollToTopButton();
        props.onPopOpen();
    }

    function ScrollToTopButton () {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
          });
    }
}
