import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import media from '../../media';
import config from './config.json';
import jsonp from 'jsonp';


export default function VideoPopup(props) {

    const [videoOverlay, setVideoOverlay] = useState(true);
    const refVideo = useRef(null);

    return (
        <div className="video-popup">
            <div className='video-container'>
                <h2 className="heading" dangerouslySetInnerHTML={{__html:config.heading}}></h2>
                <span className='close-btn' onClick={onClose}></span>

                <div className='video-content'>
                    <div className='video-block'>
                        <video className="video" ref={refVideo} controls={videoOverlay?false:true}  controlsList="nodownload" playsInline>
                            <source src={media[config.video]} type="video/mp4" />
                        </video>

                        {videoOverlay &&
                            <div className="overlay" onClick={onOverlayClick}>
                                <span className="play-icon"></span>
                            </div>
                        }
                    </div>
                    <div className='video-text'>
                        <div className="paragraphs">
                            <p dangerouslySetInnerHTML={{__html:config.paragraph1}}></p><br></br>
                            <p dangerouslySetInnerHTML={{__html:config.paragraph2}}></p>
                            <a className='subscribe-btn' dangerouslySetInnerHTML={{__html:config.button}} onClick={onSubscribe}></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

    function onOverlayClick(e) {

        setVideoOverlay(false);
        refVideo.current.play();
    }
    
    function onClose() {
        props.onClose();
    }

    function onSubscribe() {
        props.onClose();
        props.onSubscribe();
    }
}
