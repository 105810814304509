import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import {sendApiRequest} from '../../utilities/general';
import Loader from "../../components/loader/main";
import AnimationOnScroll from '../../components/animation-on-scroll/main';

export default function Contact(props) {

    const [formDisabled, setFormDisabled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [comments, setComments] = useState("");


    return (
        <div className="contact">
            <Layout pageId={3}>
                <div className="form-block">
                    <h1 className="heading" dangerouslySetInnerHTML={{__html:config.form.heading}}></h1>
                    <p className="paragraph" dangerouslySetInnerHTML={{__html:config.form.body}}></p>

                    <form className='form-screen' onSubmit={onFormSubmit}>
                        <div className="form-row">
                            <div className="form-col form-col1">
                                <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="fullName" disabled={formDisabled?"disabled":""} placeholder={config.form.fullName} value={fullName} required onChange={(e)=>{setFullName(e.target.value)}} />
                            </div>
                            <div className="form-col form-col2">
                                <input className={"text-input"+(formDisabled?" disabled":"")} type="email" name="email" disabled={formDisabled?"disabled":""} placeholder={config.form.email} value={email} required onChange={(e)=>{setEmail(e.target.value)}} />
                            </div>
                        </div>
                        <div className="form-row">
                            <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="phone" disabled={formDisabled?"disabled":""} placeholder={config.form.phone} value={phone} required onChange={(e)=>{setPhone(e.target.value.replace(/\D/g,''))}} />
                        </div>

                        <div className="form-row">
                            <label className='dropdown-label' htmlFor="dropdown">
                                {config.form.question}
                            </label>
                            <select className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} id="dropdown" name="selectedOption" value={selectedOption} required onChange={handleDropdownChange}>
                                <option value="">*Choose an option</option>
                                <option value="Advocate">Advocate</option>
                                <option value="Architect">Architect </option>
                                <option value="Chameleon">Chameleon</option>
                                <option value="Coach">Coach</option>
                                <option value="Controller">Controller </option>
                                <option value="Developer">Developer</option>
                                <option value="Entrepreneur">Entrepreneur</option>
                                <option value="Explorer">Explorer</option>
                                <option value="Facilitator">Facilitator</option>
                                <option value="Generalist">Generalist</option>
                                <option value="Idealist">Idealist</option>
                                <option value="Presenter">Presenter</option>
                                <option value="Producer">Producer</option>
                                <option value="Promoter">Promoter</option>
                                <option value="Specialist">Specialist</option>
                                <option value="Supporter">Supporter</option>
                                <option value="Traditionalist">Traditionalist</option>
                            </select>
                        </div>

                        <div className="form-row">
                            <textarea className={"text-area"+(formDisabled?" disabled":"")} name="comments" disabled={formDisabled?"disabled":""} placeholder={config.form.comments} value={comments} required onChange={(e)=>{setComments(e.target.value)}}></textarea>
                        </div>

                        <div className='form-row'>
                            <label className='dropdown-label'>
                                <input className='checkbox'disabled={formDisabled?"disabled":""} name="checkbox" required={!formDisabled} type="checkbox" onChange={handleCheckboxChange}/>
                                {config.form.text1}
                                <a className="terms-link" href="/terms#privacy">Privacy Policy</a>
                                {config.form.text2}
                                <a className="privacy-link" href="/terms">Terms & Conditions</a>
                            </label>
                        </div>

                        {showResponse ?
                            <div className="response">
                                {config.form.response.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                            </div>
                        :
                            <div className="buttons-holder">
                                {isSending ?
                                    <Loader />
                                :
                                    <span className="submit-btn" onClick={onFormSubmit}>
                                        <input type="submit" value={config.form.submitBtn} />
                                    </span>
                                }
                            </div>
                            }
                    </form>
                </div>

                <div className="powered-block">
                    <AnimationOnScroll>
                        <div className="paragraphs">
                                {config.powered.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                        </div>
                    </AnimationOnScroll>
                </div>
            </Layout>
        </div>
    )

    function handleDropdownChange(e) {
        setSelectedOption(e.target.value);
    }

    function isEmailCorrect(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function onFormSubmit(e) {
        // e.preventDefault();

        //Validate form
        if (fullName==="" || email==="" || comments==="" ) {
            alert(config.form.error1);
            return;
        }

        if (!isChecked) {
            alert(config.form.error3);
            return;
        }

        if (isEmailCorrect(email)===false) {
            alert(config.form.error2);
            return;
        }

        //Send contact email
        setIsSending(true);

        const data = {
            email: email,
            fullName: fullName,
            phone: phone,
            selectedOption: selectedOption,
            comments: comments
        }

        const body = {
            action: "send_contact_email",
            data: JSON.stringify(data)
        }

        var res = await sendApiRequest("POST", body);

        console.log(res);

        setIsSending(false);
        setFormDisabled(true);
        setShowResponse(true);
    }

    function handleCheckboxChange(e) {
        setIsChecked(!isChecked);
      };
}
