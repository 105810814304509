export async function sendApiRequest(method, body) {
    if (method==="GET") {
        const response = await fetch("/api"+convertObjectToGetString(body));

        return response.json();
    }
    else if (method==="POST") {
        const response = await fetch('https://ezeni-sendgrid-nextjs.vercel.app/api', {method:'POST', body:convertObjectToFormData(body)});

        return response.json();
    }
    else {
        return null;
    }
}

export function convertObjectToGetString(data) {
    var getString = "?";
    var properties = Object.keys(data);
    var i;

    for (i=0; i<properties.length; i++) {
        getString += properties[i]+"="+data[properties[i]]+"&";
    }

    getString = getString.substring(0, getString.length-1);

    return getString;
}

export function convertObjectToFormData(data) {
    var formData = new FormData();
    var properties = Object.keys(data);
    var i;

    for (i=0; i<properties.length; i++) {
        formData.set(properties[i], data[properties[i]]);
    }

    return formData;
}
