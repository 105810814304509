import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import Topbar from "../topbar/main";
import Footer from "../footer/main";
import SubscribePopup from "../subscribe-popup/main";
import VideoPopup from "../../components/video-popup/main";
import Cookies from 'js-cookie';

export default function Layout(props) {
    const [showPopup, setShowPopup] = useState(false);
    // Check if the 'visitedHomepage' cookie exists
    const visitedWebsite = Cookies.get('visitedWebsite');
    const [showVideoPopup, setShowVideoPopup] = useState(visitedWebsite);

    useEffect(() => {

        // If the cookie does not exist, set the cookie
        if (!visitedWebsite) {
          Cookies.set('visitedWebsite', 'true', { expires: 120/1440 }) // Cookie expires in 7 days
        }
      }, []);



    return (
        <div className="layout">
            <Topbar pageId={props.pageId}  onPopOpen={()=>{setShowVideoPopup(false);}}/>

            <div className="content">
                {props.children}
            </div>

            <Footer onSubscribe={()=>{setShowPopup(true)}}  onPopOpen={()=>{setShowVideoPopup(false);}}/>

            {showPopup && <SubscribePopup onClose={()=>{setShowPopup(false)}} />}

            {!showVideoPopup &&<VideoPopup  onClose={()=>{setShowVideoPopup(true);}} onSubscribe={()=>{setShowPopup(true);}}/>}
        </div>
    )
}
