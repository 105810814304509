import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function Polaroids(props) {
    return (
        <div className={"polaroids"}>
            <div className="items">
                {props.content.map((item, key) => {
                    return(
                        <div className='item' key={key}>
                            <a className="block-content" href={item.url}>
                                <div className={"item-img"}>
                                    <img src={media[item.img]} />

                                    {item.soon &&
                                    <img className='coming-soon' src={media["coming_soon"]} />}

                                </div>
                                <div className={"item-label"}>
                                    <h3 className="label" dangerouslySetInnerHTML={{__html:item.label}}></h3>
                                    <span className="arrow"></span>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
