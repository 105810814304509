const media = {

    //Images
    ezeni_logo: require("./imgs/ezeni_logo.svg").default,

    coach_matt: require("./imgs/coaches/matt.jpg"),
    coach_jason: require("./imgs/coaches/jason.jpg"),
    coach_gene: require("./imgs/coaches/genevieve.jpg"),
    coach_phil: require("./imgs/coaches/phil.jpg"),

    training1: require("./imgs/offerings/leadership.jpg"),
    training2: require("./imgs/offerings/talent.jpg"),
    training3: require("./imgs/offerings/virtual.jpg"),

    contact_bg: require("./imgs/contact_bg.svg").default,
    promise_bg: require("./imgs/promise_bg.svg").default,
    transform_bg: require("./imgs/transform_bg.svg").default,
    coaches_bg: require("./imgs/coaches_bg.svg").default,

    coming_soon: require("./imgs/coming_soon.svg").default,
    // close_btn: require("./imgs/close_btn.svg").default,
    close_btn: require("./imgs/close_btn.jpg"),

    pop_vid_thumbnail: require("./imgs/pop_vid_thumbnail2.jpg"),

    // coming_soon_thumbnail: require("./imgs/coming-soon-thumbnail.svg").default,

    //Video
    homepage_video: require("./video/homepage_vid.mp4"),
    mobile_video: require("./video/homepage_mobile.mp4"),
    tip_1_video: require("./video/Tip-1.mp4"),
    coaches_matt: require("./video/Coaches_Matt.mp4"),
    coaches_jason: require("./video/Coaches_Jason.mp4"),
    coaches_gene: require("./video/Coaches_Genevieve.mp4"),
    coaches_phil: require("./video/Coaches_Phil.mp4"),

    matt_square: require("./video/Matt_Square.mp4"),
    jason_square: require("./video/Jason_Square.mp4"),
    gene_square: require("./video/Gene_Square.mp4"),
    phil_square: require("./video/Phil_Square.mp4"),

    popup_video: require("./video/homepage_popup.mp4"),

}

export default media;