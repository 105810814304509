import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import config from './config.json';
import {sendApiRequest} from '../../utilities/general';
import jsonp from 'jsonp';


export default function SubscribePopup(props) {

    const [formDisabled, setFormDisabled] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [industry, setIndustry] = useState("");
    const [job, setJob] = useState("");
    const [discovered, setDiscovered] = useState("");
    const [subscribed, setSubscribed] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="subscribe-popup">
            <div className='subscribe-block'>

                <h2 className="heading" dangerouslySetInnerHTML={{__html:config.heading}}></h2>
                <span onClick={onClose} className='close-btn'></span>
                
                <form className="subscribe-form" onSubmit={onSubscribe}>
                    <div className="form-row">
                        <input className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="text" name="FNAME" placeholder={config.firstName} value={firstName} required onChange={(e)=>{setFirstName(e.target.value)}} />
                    </div>
                    <div className="form-row">
                        <input className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="text" name="LNAME" placeholder={config.lastName} value={lastName} required onChange={(e)=>{setLastName(e.target.value)}} />
                    </div>

                    <div className="form-row">
                        <input className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="email" name="EMAIL" value={email} placeholder={config.subscribeBtn} required onChange={(e)=>{setEmail(e.target.value)}}/>
                    </div>

                    <div className="form-row">
                        <input className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="text" name="INDUSTRY" placeholder={config.industry} value={industry} onChange={(e)=>{setIndustry(e.target.value)}} />
                    </div>
                    
                    <div className="form-row">
                        <input className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="text" name="JOB" placeholder={config.job} value={job} onChange={(e)=>{setJob(e.target.value)}} />
                    </div>

                    <div className="form-row">
                        <label className='dropdown-label' htmlFor="dropdown">
                            {config.question}
                        </label>
                        <select className={"text-input"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} id="dropdown" name="DISCOVERED" value={discovered} required onChange={handleDropdownChange}>
                            <option value="">*Choose an option</option>
                            <option value="Online">Search engine (Google, Yahoo, etc.)</option>
                            <option value="Friend">Recommended by friend or collegue</option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Youtube">Youtube</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className='form-row'>
                        <label className='dropdown-label'>
                            <input className={"checkbox"+(formDisabled?" disabled":"")} disabled={formDisabled?"disabled":""} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} required/>
                             {config.text1}
                             <a className="terms-link" href="/terms#privacy" onClick={onClose}>Privacy Policy</a>
                             {config.text2}
                             <a className="privacy-link" href="/terms" onClick={onClose}>Terms & Conditions</a>
                        </label>
                    </div>

                    {subscribed === false ? (
                        <div className="buttons-holder">
                            <div>
                                <span className="submit-btn">
                                    <input className='submit-btn' type="submit" value="Sign up" onChange={handleCheckboxChange}/>
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className='response'>
                            <p>{config.subscribedText}</p>
                        </div>

                    )}
                </form>
            </div>

        </div>
    )

    async function onSubscribe(e) {

        // props.onSubscribe();

        e.preventDefault();
        //This below goes to popup component
        const url = 'https://ezeni.us21.list-manage.com/subscribe/post?u=d3582d0112e3d28e39d9ca22e&amp;id=ba9cd02ce6&amp;f_id=0012e4e6f0';

        const params = {
            EMAIL: email,
            FNAME: firstName,
            LNAME: lastName,
            INDUSTRY: industry,
            JOB: job,
            DISCOVERED: discovered,
          };
      
        const queryString = Object.keys(params)
            .map(key => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
        
        const fullUrl = `${url}&${queryString}`;

        jsonp(fullUrl, { param: 'c' }, (_, data) => {
            console.log(data);
        });

        //Update state
        setSubscribed(true);
        setFormDisabled(true);
    }

    function onClose() {

        props.onClose();

    }

    function handleDropdownChange(e) {
        setDiscovered(e.target.value);
    }

    function handleCheckboxChange(e) {
        setIsChecked(!isChecked);
      };
    
}
