import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import Polaroids from "../../components/polaroids/main";
import AnimationOnScroll from '../../components/animation-on-scroll/main';
import AnimationOnScroll2 from '../../components/animation-on-scroll2/main';
import AnimationOnScroll3 from '../../components/animation-on-scroll3/main';
import CardAnimation from '../../components/card-animation/main';


export default function Home(props) {
    const [volumeValue, setVolumeValue] = useState(0);
    const [showDesktopVideo, setShowDesktopVideo] = useState(true);
    const [showMobileVideo, setShowMobileVideo] = useState(false);

    const videoRef = useRef(null);

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize); //Updates reveal height when resizing window

        return () => {
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);
    
    return (
        <div className="home">
            <Layout pageId={0}>
            <div className="banner-video-block">
                {showDesktopVideo &&
                    <video className="video-desktop" ref={videoRef} muted={volumeValue===0?true:false} playsInline autoPlay loop>
                        <source src={media[config.banner.video]} type="video/mp4" />
                    </video>
                }

                {showMobileVideo &&
                    <video className="video-mobile" ref={videoRef} muted={volumeValue===0?true:false} playsInline autoPlay loop>
                        <source src={media[config.mobile.video]} type="video/mp4" />
                    </video>
                }

                <div className="video-controls">
                    <span className={"mute-btn"+(volumeValue===0?"":" unmuted")} onClick={toggleMute}></span>
                    <input className="volume-range" type="range" min={0} max={100} step={1} value={volumeValue} onChange={adjustVolume} label="volume-slider"/>
                </div>
            </div>

            <div className="intro-block">
                <h1 className="heading" dangerouslySetInnerHTML={{__html:config.intro.heading}}></h1>
                <div className="paragraphs">
                    {config.intro.body.map((paragraph, key) => {
                        return(
                            <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                        );
                    })}
                </div>
            </div>

            <div className="leader-block">
                <AnimationOnScroll2>
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.leader.heading}}></h2>
                </AnimationOnScroll2>
                <AnimationOnScroll3>
                    <div className="paragraphs">
                        {config.leader.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </AnimationOnScroll3>
            </div>

            <CardAnimation>
                <div className="coaches-block">
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.coaches.heading}}></h2>
                    <Polaroids alt="Leadership coaches" content={config.coaches.polaroid} />
                </div>
            </CardAnimation>

            <div className="promise-block">
                <AnimationOnScroll2>
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.promise.heading}}></h2>
                </AnimationOnScroll2>
                <AnimationOnScroll3>
                    <div className="paragraphs">
                        {config.promise.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                </AnimationOnScroll3>
            </div>

            <div className="offerings-block">
                <AnimationOnScroll>
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.offerings.heading}}></h2>
                    <Polaroids alt="Coaching offerings" content={config.offerings.training} />
                </AnimationOnScroll>
            </div>

            <div className="transform-block">
                <AnimationOnScroll2>
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.transform.heading}}></h2>
                </AnimationOnScroll2>
                
                <AnimationOnScroll3>
                    <div className="paragraphs">
                        {config.transform.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                    <a className="transform-btn" dangerouslySetInnerHTML={{__html:config.transform.button}} href={config.transform.url}></a>
                </AnimationOnScroll3>
            </div>

            <div className="powered-block">
                <AnimationOnScroll2>
                    <div className="paragraphs">
                            {config.powered.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                    </div>
                </AnimationOnScroll2>
            </div>
        </Layout>
    </div>
    )

    function toggleMute(e) {
        const video = videoRef.current;

        if (volumeValue<=0) {
            video.volume = 0.50;
            setVolumeValue(50);
        }
        else {
            video.volume = 0;
            setVolumeValue(0);
        }
    }

    function adjustVolume(e) {
        const video = videoRef.current;
        video.volume = e.target.value/100;
        setVolumeValue(e.target.value);
        if (video.volume<=0) {
            toggleMute();
        }
    }

    function onWindowResize() {

        if (window.innerWidth<=850) {
            setShowDesktopVideo(false);
            setShowMobileVideo(true);
        }
        else {
            setShowDesktopVideo(true);
            setShowMobileVideo(false);
        }
    }
}
