import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import Polaroids from "../../components/polaroids/main";
import AnimationOnScroll from '../../components/animation-on-scroll/main';
import AnimationOnScroll2 from '../../components/animation-on-scroll2/main';
import AnimationOnScroll3 from '../../components/animation-on-scroll3/main';
import CardAnimation from '../../components/card-animation/main';

export default function Coach1(props) {

    const [videoOverlay, setVideoOverlay] = useState(true);
    const refVideo = useRef(null);

    return (
        <div className="coach1">
            <Layout pageId={1}>
                <div className="breadcrumb-block">
                    <p className="breadcrumb-text" dangerouslySetInnerHTML={{__html:config.breadcrumb.body}}></p>
                </div>

                <div className='video-section'>
                    <div className='video-container'>
                        <div className="video-block">
                            <div className="video-holder">
                                <video ref={refVideo} controls={videoOverlay?false:true}  controlsList="nodownload"  playsInline>
                                    <source src={media[config.coach1.video]} type="video/mp4" />
                                </video>

                                {videoOverlay &&
                                    <div className="overlay" onClick={onOverlayClick}>
                                        <span className="play-icon"></span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="bio-block">
                            <h2 className="heading" dangerouslySetInnerHTML={{__html:config.bio.heading}}></h2>
                            <div className="paragraphs">
                                {config.bio.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="transform-block">
                    <AnimationOnScroll2>
                        <h1 className="heading" dangerouslySetInnerHTML={{__html:config.transform.heading}}></h1>
                    </AnimationOnScroll2>

                    <AnimationOnScroll3>
                        <div className="paragraphs">
                            {config.transform.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                        <a className="transform-btn" dangerouslySetInnerHTML={{__html:config.transform.button}} href={config.transform.url}></a>
                    </AnimationOnScroll3>
                </div>

                <CardAnimation>
                    <div className="coaches-block">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.coaches.heading}}></h2>
                        <Polaroids alt="Leadership coaches" content={config.coaches.polaroid} />
                    </div>
                </CardAnimation>

                <div className="offerings-block">
                    <AnimationOnScroll>
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.offerings.heading}}></h2>
                        <Polaroids content={config.offerings.training} />
                    </AnimationOnScroll>
                </div>

                <div className="powered-block">
                    <AnimationOnScroll2>
                        <div className="paragraphs">
                                {config.powered.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                        </div>
                    </AnimationOnScroll2>
                </div>
            </Layout>
        </div>
    )

    function onOverlayClick(e) {

        setVideoOverlay(false);
        refVideo.current.play();
    }
}
